import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router"
import { BasePage } from "./BasePage.js"
import { Button, Container, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { getBenefit, addBenefit, modifyBenefit } from '../api/benefit-api.js';
import { useForm } from "react-hook-form";
import { strToNbr, nbrToStr, isOTPBenefit } from '../util/helper.js';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '35ch',
        }
    },
    mj: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '60ch',
        }
    },
}));


function BenefitDetailsPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const { id } = props.match.params
    const isNewBenefitCreation = props.match.path === "/create-benefit"
    const { register, handleSubmit, setValue, watch } = useForm();

    const [fetchedBenefit, setFetchedBenefit] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            const result = await getBenefit(id)
            if (result) {
                setFetchedBenefit(result)
            }
        }
        fetchData();
    }, [id]);

    useEffect(() => {
        setValue("displayOrder", fetchedBenefit.displayOrder)
        setValue("name", fetchedBenefit.name)
        setValue("taxMultiplier", fetchedBenefit.taxMultiplier)
        setValue("limitYearlyNet", nbrToStr(fetchedBenefit.limitYearlyNet))
        setValue("limitMonthlyNet", nbrToStr(fetchedBenefit.limitMonthlyNet))
        setValue("comment", fetchedBenefit.comment)
        setValue("limitConditionalYearlyNet", nbrToStr(fetchedBenefit.limitConditionalYearlyNet))
        setValue("limitFirstSixMonthsNet", nbrToStr(fetchedBenefit.limitFirstSixMonthsNet))
    }, [fetchedBenefit]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (benefit) => {
        benefit.limitMonthlyNet = strToNbr(benefit.limitMonthlyNet)
        benefit.limitYearlyNet = strToNbr(benefit.limitYearlyNet)
        benefit.limitFirstSixMonthsNet = strToNbr(benefit.limitFirstSixMonthsNet)
        benefit.limitConditionalYearlyNet = strToNbr(benefit.limitConditionalYearlyNet)

        if (isNewBenefitCreation) {
            await addBenefit(benefit)
        } else {
            benefit.id = id
            await modifyBenefit(benefit)
        }

        history.push("/benefits")
    }

    return (
        <BasePage>
            <Container align="center">
                <h2>
                    Tikett {isNewBenefitCreation ? "hozzáadás" : "módosítás"}
                </h2>
                <Breadcrumb elements={[{ name: "Admin", href: "/admin" }, { name: "Tikketek", href: "/benefits" }, { name: "Kezelés", href: "" }]} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <TextField type="number" InputLabelProps={{ shrink: watch("displayOrder") !== "" }} name="displayOrder" fullWidth={true} label="Sorrend" inputRef={register({ required: true })} />
                    </div>
                    <div>
                        <TextField InputLabelProps={{ shrink: watch("name") !== "" }} name="name" fullWidth={true} label="Név" inputRef={register({ required: true })} />
                    </div>
                    <div>
                        <TextField
                            name="taxMultiplier"
                            type="number"
                            InputLabelProps={{ shrink: watch("taxMultiplier") !== "" }}
                            inputProps={{
                                step: "0.000001",
                            }}
                            label="SZJA szorzó"
                            fullWidth={true}
                            inputRef={register({ required: true })} />
                    </div>
                    <div>
                        <TextField
                            InputLabelProps={{ shrink: watch("limitYearlyNet") !== "" }}
                            name="limitYearlyNet"
                            fullWidth={true}
                            label="Maximum nettó összeg évente"
                            inputRef={register}
                            onChange={(e) => {
                                e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                            }}
                            onKeyPress={(e) => {
                                if (! "0123456789".includes(e.key)) {
                                    e.preventDefault()
                                }
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            InputLabelProps={{ shrink: watch("limitMonthlyNet") !== "" }}
                            name="limitMonthlyNet"
                            fullWidth={true}
                            label="Maximum nettó összeg havonta"
                            inputRef={register}
                            onChange={(e) => {
                                e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                            }}
                            onKeyPress={(e) => {
                                if (! "0123456789".includes(e.key)) {
                                    e.preventDefault()
                                }
                            }}
                        />

                    </div>
                    {isOTPBenefit(fetchedBenefit.id) &&
                        <>
                            <div>
                                <TextField
                                    InputLabelProps={{ shrink: watch("limitFirstSixMonthsNet") !== "" }}
                                    name="limitFirstSixMonthsNet"
                                    fullWidth={true}
                                    label="Maximum nettó 1. féléves limit"
                                    inputRef={register}
                                    onChange={(e) => {
                                        e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                                    }}
                                    onKeyPress={(e) => {
                                        if (! "0123456789".includes(e.key)) {
                                            e.preventDefault()
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    InputLabelProps={{ shrink: watch("limitConditionalYearlyNet") !== "" }}
                                    name="limitConditionalYearlyNet"
                                    fullWidth={true}
                                    label="Maximum nettó 2. féléves limit"
                                    inputRef={register}
                                    onChange={(e) => {
                                        e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                                    }}
                                    onKeyPress={(e) => {
                                        if (! "0123456789".includes(e.key)) {
                                            e.preventDefault()
                                        }
                                    }}
                                />
                            </div>
                        </>
                    }
                    <div>
                        <TextField
                            InputLabelProps={{ shrink: watch("comment") !== "" }}
                            name="comment"
                            fullWidth={true}
                            inputRef={register}
                            label="Megjegyzés"
                            multiline
                            rows={5}
                            rowsMax={20}
                        />
                    </div>
                    <br /><br />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        Mentés
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        href="/benefits"
                    >
                        Mégse
                    </Button>
                </form>
            </Container>
        </BasePage >
    )

}

export default BenefitDetailsPage