import { getCurrentUser } from './user-api.js'

const preProcess = (res) => {
    if (!res) {
        throw new Error("Missing result")
    }
    if ((res.status >= 200 && res.status < 400) || (res.status === 409) || (res.status === 422)) {
        return res
    }

    if (res.status === 403) {
        logout()
    }

    throw new Error("Bad status: " + res.status)
}

const makeRequest = (path, method = "GET", bodyValues, formValues) => {
    const jwtToken = localStorage.getItem('jwt-token');
    // Todo check this
    // const allowedOrigins = [apiUrl];
    // https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81
    // if (allowedOrigins.includes(origin)) {
    //     config.headers.authorization = `Bearer ${token}`;
    // }

    const headers = new Headers()
    headers.append("authorization", `Bearer ${jwtToken}`);

    let body = null
    if (bodyValues && formValues) {
        return Promise.reject("Both bodValues and formValues are set")
    }
    if (bodyValues) {
        headers.append("Content-Type", "application/json")
        body = JSON.stringify(bodyValues)
    }
    if (formValues) {
        const formData = new FormData()
        for (const key in formValues) {
            const formValue = formValues[key]
            const value = Array.isArray(formValue) ? JSON.stringify(formValue) : formValue
            formData.append(key, value)
        }

        body = formData
    }
    const request = new Request("/api/v1/" + path, {
        method,
        headers,
        body,
        credentials: "same-origin",
    })
    return fetch(request)
}

const login = async (username, password) => {
    const bodyValues = { username: username, password: password }
    const response = await makeRequest(`auth/login`, "POST", bodyValues, null)
    const preProc = await preProcess(response)
    const jsonResp = await preProc.json()
    const jwtToken = jsonResp.accessToken
    localStorage.setItem("jwt-token", jwtToken)
    const currentUser = await getCurrentUser()
    localStorage.setItem("current-user-id", currentUser.id)
    localStorage.setItem("current-user-is-admin", currentUser.isAdmin)
    localStorage.setItem("current-user-name", currentUser.lastName + " " + currentUser.firstName)
    return currentUser
}

const logout = () => {
    localStorage.clear()
    window.location.href = "/login";
}

export {
    preProcess,
    makeRequest,
    login,
    logout,
}
