import React from 'react';
import { BasePage } from "./BasePage.js"
import { Container, Box, Typography, Link, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { downloadCompanySummary } from "../api/benefit-api"

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '200px',
        }
    },
}));


function QueriesPage(props) {
    const classes = useStyles();
    return (
        <BasePage>
            <Typography variant="h4">
                Lekérdezések
            </Typography>
            <Breadcrumb elements={[{ name: "Admin", href: "/admin" }, { name: "Lekérdezések", href: "/queries" }]} />
            <Container maxWidth="sm">
                <Paper>
                    <Box className={classes.root}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Link href="" onClick={async (e) => {
                            e.preventDefault()
                            return await downloadCompanySummary()
                        }}>
                            Összesítő és havi kimutatás letöltése
                        </Link>
                    </Box>
                    <br />
                    <Box>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            href="/admin"
                        >
                            Mégse
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </BasePage >
    )

}

export default QueriesPage