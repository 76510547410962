import React from 'react'
import { Box } from '@material-ui/core'

export function ShowFinalizeStat(props) {
    const { countAll, countTouched, countFinalized } = props
    return (
        <Box textAlign="left">
            Kitöltés alatt
            <Box fontSize={18} component="span" color="info.dark"> {countTouched}</Box>
            , amiből
            <Box fontSize={18} component="span" fontWeight="fontWeightMedium" color="success.dark"> {countFinalized} </Box>
            véglegesített.
            <Box component="span"> (Összesen: {countAll}</Box>)
        </Box>
    )
}