import React, { useEffect, useState } from 'react';
import { getUser } from '../api/user-api.js'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

export const getFullName = (user) => {
    return `${user.lastName} ${user.firstName}`
}

export const useFetchUser = (userId, fetchAgain) => {
    const [user, setUser] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            if (!userId) {
                return {}
            }
            const result = await getUser(userId)
            if (result) {
                if (!result.limitsMonthlyGross) {
                    result.limitsMonthlyGross = [null, null, null, null, null, null, null, null, null, null, null, null]
                }
                setUser(result)
            }
        }
        fetchData();
    }, [userId, fetchAgain]);

    return user
}

export const getMonthName = (m) => {
    const monthNames = ["Január", "Február", "Március", "Április", "Május", "Június",
        "Július", "Augusztus", "Szeptember", "Október", "November", "December"
    ];

    return monthNames[m - 1];
}

export const strToNbr = (s) => {
    if (!s) {
        return null
    }
    return Number(s.replace(/\s/g, ''))
}

export const getUserLimitYearlyGross = (limitsMonthlyGross) => {
    return limitsMonthlyGross && limitsMonthlyGross.reduce((acc, cur) => acc + cur, 0)
}

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const nbrToStr = (n) => {
    return n || n === 0 ? n.toLocaleString("hu-HU") : ""
}

export const isOTPBenefit = (benefitId) => {
    return [1, 2, 3].includes(benefitId)
}

export const isSZEPSzallasBenefit = (benefitId) => {
    return benefitId === 3
}

export const getDayCareBenefitId = () => {
    return 5
}

export const isOTPBenefitHighTaxRated = (benefitId) => {
    return [7, 8, 9].includes(benefitId)
}

export const isHiddenBenefit = (benefitId)=> {
    return [1, 2, 8, 9].includes(benefitId)    
}

export const getCorrespondingStandardBenefitId = (benefitId) => {
    switch (benefitId) {
        case 7:
            return 3
        case 8:
            return 2
        case 9:
            return 1
        default:
            return -1
    }
}

export const isPaidInCashBenefit = (benefitId) => {
    return benefitId === 4
}


export const SimpleDialog = (props) => {
    const { title, message, isOpen, handleClose } = props
    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle id="simple-dialog-title"> {title} </DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const ConfirmDialog = (props) => {
    const { title, message, isOpen, handleClose, submit } = props
    const handleSubmit = () => {
        handleClose()
        submit()
    }
    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle id="simple-dialog-title"> {title} </DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Mégse
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const DisablePageDialog = (props) => {
    const { title, message, isOpen, fromUrl } = props
    return (
        <Dialog
            open={isOpen}
        >
            <DialogTitle id="simple-dialog-title"> {title} </DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button href={fromUrl} color="primary">
                    Vissza
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// I wish I knew math well enough to calculate this without this ugly way
export const calcYearlyUserLimitRemainingNet = (alreadyAllocatedGross, limitGross, taxMultiplier) => {
    const diff = limitGross - alreadyAllocatedGross
    let target = Math.ceil(diff / taxMultiplier)
    // toGross + already
    if (Math.round(target * taxMultiplier) + alreadyAllocatedGross <= limitGross) {
        return target
    }

    return Math.floor(diff / taxMultiplier)
}

export const isAdmin = () => { return localStorage.getItem("current-user-is-admin") === "true" }
