import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router"
import { BasePage } from "./BasePage.js"
import { useForm, Controller } from "react-hook-form";
import { Button, Container, Link, Box, Typography, TextField, Tooltip, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { addUser, modifyUser, resetPassword } from '../api/user-api.js';
import { ERROR_MESSAGES } from '../util/constants.js'
import { Breadcrumb } from "../components/Breadcrumb.js"
import { useFetchUser, isEmptyObject } from '../util/helper.js';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const ResetPasswordDialog = (props) => {
    const { userId, isOpen, handleClose } = props
    const [newPassword, setNewPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle id="simple-dialog-title"> Jelszó módosítás </DialogTitle>
            <DialogContent>
                <TextField
                    label="Új jelszó"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={async () => {
                        const { status } = await resetPassword(userId, newPassword)
                        if (status === 200) {
                            console.info("Password successfully changed")
                        } else {
                            setErrorMessage("Hiba!")
                        }
                        setNewPassword("")
                        handleClose()
                    }}
                >
                    OK
                </Button>
                <Box color="error.main">
                    {errorMessage}
                </Box>
            </DialogActions>
        </Dialog >
    )
}



function UserDetailPage(props) {
    const history = useHistory();
    const classes = useStyles();
    const { userId } = props.match.params

    const { control, register, handleSubmit, setValue, errors } = useForm();

    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorMessageUsername, setErrorMessageUsername] = useState("")
    const [errorMessageTaxId, setErrorMessageTaxId] = useState("")

    // react-form-hook and material UI Checkbox does not like each other that much...
    const [isAdmin, setIsAdmin] = useState(false)
    const [isTestUser, setIsTestUser] = useState(false)

    const fetchedUser = useFetchUser(userId)
    useEffect(() => {
        if (!isEmptyObject(fetchedUser)) {
            setValue("username", fetchedUser.username)
            setValue("firstName", fetchedUser.firstName)
            setValue("lastName", fetchedUser.lastName)
            setValue("department", fetchedUser.department)
            setValue("taxId", fetchedUser.taxId)
            setIsAdmin(fetchedUser.isAdmin)
            setIsTestUser(fetchedUser.isTestUser)
        }
    }, [fetchedUser]) // eslint-disable-line react-hooks/exhaustive-deps


    const isNewUserCreation = props.match.path === "/create-user"

    const resetErrorMassages = () => {
        setErrorMessageUsername("")
        setErrorMessageTaxId("")
    }

    const onSubmit = async (user) => {
        user.isAllocationFinalized = fetchedUser.isAllocationFinalized
        user.limitsMonthlyGross = fetchedUser.limitsMonthlyGross
        user.isAdmin = isAdmin
        user.isTestUser = isTestUser

        resetErrorMassages()
        user.id = userId
        let response = {}
        if (isNewUserCreation) {
            response = await addUser(user)
        }
        else {
            response = await modifyUser(user)
        }

        if (!response) {
            setErrorMessage("Hiba!");
            return
        }
        switch (response.status) {
            case 200:
                break;
            case 409:
                if (response.data.errors.find((e) => e.field === "username")) {
                    setErrorMessageUsername(ERROR_MESSAGES.FIELD_ALREADY_EXISTS)
                }
                if (response.data.errors.find((e) => e.field === "taxId")) {
                    setErrorMessageTaxId(ERROR_MESSAGES.FIELD_ALREADY_EXISTS)
                }
                break;
            default:
                setErrorMessage("Hiba!");
        }

        history.push("/users")
    }

    return (
        <BasePage>
            <ResetPasswordDialog userId={userId} isOpen={showResetPasswordDialog} handleClose={() => setShowResetPasswordDialog(false)} />
            <Container align="center">
                <Typography variant="h4">
                    Felhasználó {isNewUserCreation ? "hozzáadás" : "módosítás"}
                </Typography>
                <Breadcrumb elements={[
                    { name: "Admin", href: "/admin" },
                    { name: "Felhasználók", href: "/users" },
                    { name: fetchedUser.username ? fetchedUser.username : "", href: `/users/${userId}` }
                ]} />
                <br />
                <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Controller
                            as={TextField}
                            control={control}
                            name="username"
                            defaultValue=""
                            rules={{ required: { value: true, message: ERROR_MESSAGES.FIELD_REQUIRED } }}
                            label="Felhasználó"
                            error={(errors.username && errors.username.message) || errorMessageUsername ? true : false}
                            helperText={(errors.username && errors.username.message) || errorMessageUsername}
                            onClick={() => setErrorMessageUsername("")}
                        />
                    </div>
                    <div>
                        <Tooltip title="A kódolatlan jelszavakat nem tároljuk, így a felhasználó jelszavát nem lehet megtekinteni.">
                            <TextField
                                name="password"
                                type="password"
                                inputRef={(isNewUserCreation && register({ required: { value: true, message: ERROR_MESSAGES.FIELD_REQUIRED } })) || null}
                                error={(errors.password && errors.password.message)}
                                helperText={(errors.password && errors.password.message)}
                                label="Jelszó"
                                defaultValue={isNewUserCreation ? "" : "***"}
                                InputProps={{
                                    readOnly: !isNewUserCreation,
                                }} />
                        </Tooltip>
                    </div>
                    <Link
                        href=""
                        style={{ fontSize: "0.7em" }}
                        onClick={(e) => {
                            e.preventDefault()
                            setShowResetPasswordDialog(true)
                        }}> Jelszó módosítása </Link>
                    <br />
                    <br />
                    <Controller as={<TextField />} control={control} name="lastName" label="Vezetéknév" defaultValue="" />
                    <br />
                    <Controller as={<TextField />} control={control} name="firstName" label="Keresztnév" defaultValue="" />
                    <br />
                    <Controller as={<TextField />} control={control} name="department" label="Munkahely/osztály" defaultValue="" />
                    <br />
                    <Controller
                        as={TextField}
                        control={control}
                        name="taxId"
                        label="Adószám"
                        defaultValue={""}
                        error={errorMessageTaxId ? true : false}
                        helperText={errorMessageTaxId}
                        onClick={() => setErrorMessageTaxId("")}
                    />
                    <br />
                    <FormControlLabel
                        control={<Checkbox name="isAdmin" checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />}
                        label="Admin"
                    />

                    <br />
                    <Tooltip title="Csak tesztelési célokra, nem kerülnek bele az adatai az összesítő kimutatásokba.">
                        <FormControlLabel
                            control={<Checkbox name="isTestUser" checked={isTestUser} onChange={(e) => setIsTestUser(e.target.checked)} />}
                            label="Teszt felhasználó"
                        />
                    </Tooltip>
                    <br />
                    <br />
                    <Link href={`/users/${userId}/benefits`}> Kafetéria igénylés </Link>
                    <br />
                    <br />
                    <Link href={`/users/${userId}/limits`}> Limitek </Link>
                    <br />
                    <br />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        Mentés
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        href="/users"
                    >
                        Mégse
                    </Button>
                    <br />
                    <Box color="error.main">
                        {errorMessage}
                    </Box>
                </form>
            </Container>
        </BasePage >
    )

}

export default UserDetailPage