import { preProcess, makeRequest, logout } from './api.js'

const getSettings = async () => {
    try {
        const response = await makeRequest(`settings/general`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        if (e === 403) {
            logout()
        }
        console.log('Failed to get settings!', e);
    }
}

const modifySettings = async (settings) => {
    try {
        const response = await makeRequest(`settings/general`, "PUT", settings, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to change settings!', e);
    }
}


export {
    getSettings,
    modifySettings,
}
