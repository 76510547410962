import React, { useEffect, useState } from 'react';
import { BasePage } from "./BasePage.js"
import { Button, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Link, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { getBenefits, deleteBenefit } from '../api/benefit-api.js';
import { nbrToStr, ConfirmDialog } from '../util/helper.js';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const useStyles = makeStyles({
    table: {
        minWidth: 300,
        '& th': {
            border: "1px blue solid",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
        }
    }
});

function BenefitAdminPage(props) {
    const classes = useStyles();
    const [fetchedBenefits, setFetchedBenefits] = useState([])
    const [selectedBenefit, setSelectedBenefit] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await getBenefits()
            if (result) {
                setFetchedBenefits(result.sort((a, b) => a.displayOrder - b.displayOrder))
            }
        }
        fetchData();
    }, [selectedBenefit]);

    return (
        <BasePage>
            <ConfirmDialog title="Törlés"
                message={<> Biztos törlöd a következő tikettet: <b> {selectedBenefit && selectedBenefit.name} </b> ? </>}
                isOpen={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                submit={() => { deleteBenefit(selectedBenefit.id); setSelectedBenefit(null) }}
            />
            <Typography variant="h4">
                Tikettek kezelése
            </Typography>
            <br />
            <Breadcrumb elements={[{ name: "Admin", href: "/admin" }, { name: "Tikketek", href: "/benefits" }]} />
            <Button
                variant="contained"
                color="primary"
                href="/create-benefit"
            >
                Új Tikett
            </Button>
            <Box align="left">
                <Button
                    color="primary"
                    href="/settings-benefit-group"
                >
                    Szép kártya egységes limitek
            </Button>
            </Box>
            <TableContainer className={classes.table}>
                <Table aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left" style={{ minWidth: "200px" }}>Név</TableCell>
                            <TableCell align="left">Sorrend</TableCell>
                            <TableCell align="left">SZJA szorzó</TableCell>
                            <TableCell align="left">Max nettó összeg évente</TableCell>
                            <TableCell align="left">Max nettó összeg havonta</TableCell>
                            <TableCell align="left">Max nettó 1. féléves limit</TableCell>
                            <TableCell align="left">Max nettó 2. féléves limit</TableCell>
                            <TableCell align="left">Megjegyzés</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fetchedBenefits.map((row) => (
                            <TableRow key={row.id} hover>
                                <TableCell><Button onClick={() => { setSelectedBenefit(row); setShowDeleteDialog(true); }}><DeleteForeverIcon color="primary" fontSize="small" /> </Button></TableCell>
                                <TableCell align="left">
                                    <Link href={`/benefits/${row.id}`}>
                                        {row.name}
                                    </Link>
                                </TableCell>
                                <TableCell align="left">{row.displayOrder}</TableCell>
                                <TableCell align="left">{row.taxMultiplier}</TableCell>
                                <TableCell align="left">{nbrToStr(row.limitYearlyNet)}</TableCell>
                                <TableCell align="left">{nbrToStr(row.limitMonthlyNet)}</TableCell>
                                <TableCell align="left">{nbrToStr(row.limitFirstSixMonthsNet)}</TableCell>
                                <TableCell align="left">{nbrToStr(row.limitConditionalYearlyNet)}</TableCell>
                                <TableCell align="left">{row.comment}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </BasePage>
    )

}

export default BenefitAdminPage