import React, { useState, useEffect } from 'react';
import { BasePage } from "./BasePage.js"
import { useForm } from "react-hook-form";
import { useHistory } from "react-router"
import { Box, Button, TextField, Grid, Typography, Table, Link, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { UserIndicator } from '../components/UserIndicator.js'
import { BenefitAllocationNotes } from '../components/BenefitAllocationNotes.js'
import { useFetchUser, getMonthName, getUserLimitYearlyGross, strToNbr, nbrToStr, isOTPBenefit, isPaidInCashBenefit, DisablePageDialog, isOTPBenefitHighTaxRated, calcYearlyUserLimitRemainingNet, isAdmin } from '../util/helper.js';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { getBenefit, getAllocationsForBenefit, setAllocationsForBenefit, getGroupLimits } from '../api/benefit-api.js';
import { zeroArray, ALLOCATION_SAVE_STATUS } from '../util/constants.js'

const LIMITLESS = 100000000
const NET_VALUE_ID_POSTFIX = "-net"
const GROSS_VALUE_ID_POSTFIX = "-gross"

const ERR_MONTH_LIMIT = "Havi teljes keret túllépés"
const ERR_MONTH_BENEFIT_LIMIT = "Havi cafeteria keret túllépés"
const ERR_MONTH_GROUP_LIMIT = "Havi összes SZÉP kártya keret túllépés"
const ERR_YEAR_LIMIT = "Éves teljes keret túllépés"
const ERR_YEAR_BENEFIT_LIMIT = "Éves cafeteria keret túllépés"
const ERR_YEAR_BENEFIT_CONDITIONAL = "Második féléves cafeteria keret túllépés"
const ERR_YEAR_BENEFIT_FIRST_SIX_MONTHS = "Első féléves cafeteria keret túllépés"
const ERR_IS_PAID_IN_CASH = "Pénzbeli megváltás más elemekkel kombinálva nem kérhető"
// does this limit exists?
const ERR_YEAR_GROUP_LIMIT = "Éves összes SZÉP kártya keret túllépés"
const ERR_ACCOUNTNUMBER_REQUIRED = "Számlaszám megadása kötelező"
const ERR_ACCOUNTNUMBER_MAX8 = "Maximum 8 karakter"
const ERR_ACCOUNTNUMBER_MIN8 = "Minimum 8 karakter"

const useStyles = makeStyles({
    table: {
        minWidth: 300,
        '& th': {
            border: "1px blue solid",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
        }
    },
    myTable: {
        border: "1px solid black",
        borderCollapse: "collapse",
        '& th': {
            border: "1px solid black",
            borderCollapse: "collapse",
        },
        '& td': {
            border: "1px solid black",
            borderCollapse: "collapse",
            textAlign: "center",
        }
    }
});

function BenefitAllocationPage(props) {
    const history = useHistory()
    const { userId, benefitId } = props.match.params
    const { register, handleSubmit, setValue, getValues, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const [showAlreadyFinalizedAlertDialog, setShowAlreadyFinalizedAlertDialog] = useState(false)
    const [activeNoteDetails, setActiveNoteDetails] = useState({})
    const [fullYearErrorMessage, setFullYearErrorMessage] = useState("")
    const [showNotValidateChkBox, setShowNotValidateChkBox] = useState(false)
    const [shouldValidate, setShouldValidate] = useState(true)
    const [showPaidInCashAlertDialog, setShowPaidInCashAlertDialog] = useState(false)

    const user = useFetchUser(userId)

    const [fetchedAllocations, setFetchedAllocations] = useState(null)
    const [fetchedBenefit, setFetchedBenefit] = useState(null)
    const [fetchedGroupLimits, setFetchedGroupLimits] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            let result = await getAllocationsForBenefit(userId, benefitId)
            if (result) {
                if (!result.allOtherGroupBenefitAllocationsMonthlyGross) {
                    result.allOtherGroupBenefitAllocationsMonthlyGross = zeroArray
                }
                if (!result.allOtherGroupBenefitAllocationsMonthlyNet) {
                    result.allOtherGroupBenefitAllocationsMonthlyNet = zeroArray
                }
                const allOtherBenefitAllocationsYearlyGross = result.allOtherBenefitAllocationsMonthlyGross.reduce((acc, cur) => acc + cur, 0)
                if (
                    (isPaidInCashBenefit(Number(benefitId)) && allOtherBenefitAllocationsYearlyGross > 0)
                    ||
                    (!isPaidInCashBenefit(Number(benefitId)) && result.exclusiveBenefitAllocated)
                ) {
                    setShowPaidInCashAlertDialog(true)
                }
                setFetchedAllocations(result)
            }

            result = await getBenefit(benefitId)
            if (result) {
                if (!result.limitMonthlyNet) {
                    result.limitMonthlyNet = LIMITLESS
                }
                // if (!result.limitYearlyNet) {
                //     result.limitYearlyNet = LIMITLESS
                // }
                setFetchedBenefit(result)
            }
            result = await getGroupLimits()
            if (result) {
                setFetchedGroupLimits(result)
            }
        }

        fetchData();

    }, [userId, benefitId]);

    const szepCardLimitMonthlyNet = fetchedGroupLimits && fetchedGroupLimits.benefitGroupMonthlyLimit
    const szepCardLimitYearlyNet = fetchedGroupLimits && fetchedGroupLimits.benefitGroupYearlyLimit

    useEffect(() => {
        setShowAlreadyFinalizedAlertDialog((user.isAllocationFinalized && !isAdmin()) || false)
        setShowNotValidateChkBox(isAdmin())
    }, [user, localStorage])

    const toGross = (netValue) => {
        if (!fetchedBenefit) {
            return 0
        }
        return Math.round(netValue * fetchedBenefit.taxMultiplier)
    }

    const toNet = (grossValue) => {
        if (!fetchedBenefit) {
            return 0
        }
        return Math.floor(grossValue / fetchedBenefit.taxMultiplier)
    }

    const userLimitYearlyGross = getUserLimitYearlyGross(user.limitsMonthlyGross)
    const allOtherBenefitAllocationsYearlyGross = fetchedAllocations && fetchedAllocations.allOtherBenefitAllocationsMonthlyGross.reduce((acc, cur) => acc + cur, 0)
    const initialAllocationsYearlyNet = (fetchedAllocations && fetchedAllocations.currentBenefitAllocationsMonthlyNet.reduce((acc, cur) => acc + cur, 0))
    const initialAllocationsYearlyGross = (fetchedAllocations && fetchedAllocations.currentBenefitAllocationsMonthlyNet.reduce((acc, cur) => acc + toGross(cur), 0))

    const sumCurrentValuesNet = (fromMonthNumber, toMonthNumber) => {
        let sumCurrentValuesNet = 0
        for (var i = fromMonthNumber; i <= toMonthNumber; i++) {
            if (getValues(i + NET_VALUE_ID_POSTFIX)) {
                sumCurrentValuesNet += strToNbr(getValues(i + NET_VALUE_ID_POSTFIX))
            }
        }
        return sumCurrentValuesNet
    }

    const sumCurrentValuesGross = () => {
        let sumCurrentValuesGross = 0
        for (var i = 1; i <= 12; i++) {
            if (getValues(i + GROSS_VALUE_ID_POSTFIX)) {
                sumCurrentValuesGross += strToNbr(getValues(i + GROSS_VALUE_ID_POSTFIX))
            }
        }
        return sumCurrentValuesGross
    }

    const fillUpValuesByFullYear = (amountForYear) => {
        const amountPerMonth = Math.floor(strToNbr(amountForYear) / 12)
        for (let i = 1; i <= 12; i++) {
            setValue(i + NET_VALUE_ID_POSTFIX, nbrToStr(amountPerMonth), { shouldValidate: true })
            setValue(i + GROSS_VALUE_ID_POSTFIX, nbrToStr(toGross(amountPerMonth)), { shouldValidate: true })
        }
        setValue("fullYearValueNet", nbrToStr(sumCurrentValuesNet(1, 12)), { shouldValidate: true })
        setValue("fullYearValueGross", nbrToStr(sumCurrentValuesGross()), { shouldValidate: true })
        setFullYearErrorMessage("")
    }

    const fillUpValuesFromCurrentMonth = (currentMonth, currentAmount) => {
        const currentAmountNumber = strToNbr(currentAmount)
        for (let i = currentMonth; i <= 12; i++) {
            setValue(i + NET_VALUE_ID_POSTFIX, nbrToStr(currentAmountNumber), { shouldValidate: true })
            setValue(i + GROSS_VALUE_ID_POSTFIX, nbrToStr(toGross(currentAmountNumber)), { shouldValidate: true })
        }
        setValue("fullYearValueNet", nbrToStr(sumCurrentValuesNet(1, 12)), { shouldValidate: true })
        setValue("fullYearValueGross", nbrToStr(sumCurrentValuesGross()), { shouldValidate: true })
        setFullYearErrorMessage("")
    }

    const getAllOtherGroupBenefitAllocationsYearlyNet = () => {
        return fetchedAllocations.allOtherGroupBenefitAllocationsMonthlyNet.reduce((acc, cur) => acc + cur, 0)
    }

    const getYearlyBenefitRemainingOverFirstSixMonthsNet = () => {
        return fetchedBenefit.limitConditionalYearlyNet - sumCurrentValuesNet(1, 6) > 0 ? fetchedBenefit.limitConditionalYearlyNet - sumCurrentValuesNet(1, 6) : 0
    }
    const fullYearValidation = () => {
        setFullYearErrorMessage("")
        if (!shouldValidate) {
            return true
        }
        if (sumCurrentValuesNet(1, 12) > (fetchedBenefit.limitYearlyNet || LIMITLESS)) {
            setFullYearErrorMessage(ERR_YEAR_BENEFIT_LIMIT)
            return false
        }
        if (userLimitYearlyGross < allOtherBenefitAllocationsYearlyGross + sumCurrentValuesGross()) {
            setFullYearErrorMessage(ERR_YEAR_LIMIT)
            return false
        }
        if (isOTPBenefit(fetchedBenefit.id) && getAllOtherGroupBenefitAllocationsYearlyNet() + sumCurrentValuesNet(1, 12) > szepCardLimitYearlyNet) {
            setFullYearErrorMessage(ERR_YEAR_GROUP_LIMIT)
            return false
        }
        if (isOTPBenefit(fetchedBenefit.id) && sumCurrentValuesNet(1, 6) > (fetchedBenefit.limitFirstSixMonthsNet)) {
            setFullYearErrorMessage(ERR_YEAR_BENEFIT_FIRST_SIX_MONTHS)
            return false
        }
        if (isOTPBenefit(fetchedBenefit.id) && getYearlyBenefitRemainingOverFirstSixMonthsNet() < sumCurrentValuesNet(7, 12)) {
            setFullYearErrorMessage(ERR_YEAR_BENEFIT_CONDITIONAL)
            return false
        }

        return true
    }

    const onSubmit = async (allocations) => {
        if (!fullYearValidation()) {
            return
        }
        const netDataArray = Object.entries(allocations).filter(f => f[0].includes(NET_VALUE_ID_POSTFIX))
        const allocationsArray = netDataArray.reduce((acc, cur) => {
            const currentValue = cur[1] === "" ? null : strToNbr(cur[1])
            acc.push(currentValue)
            return acc
        }, [])
        const accountNumber = allocations.accountNumber === "" ? null : allocations.accountNumber
        const body = {
            valuesMonthlyNet: allocationsArray,
            accountNumber: accountNumber,
            email: allocations.email,
        }

        const { status } = await setAllocationsForBenefit(userId, benefitId, body)
        let payload = { allocationSaveStatus: ALLOCATION_SAVE_STATUS.error }
        if (status === 200) {
            payload = { allocationSaveStatus: ALLOCATION_SAVE_STATUS.success }
        }
        history.push(`/users/${userId}/benefits`, payload)
    }

    // This is so said. I should've used useEffect to fill up default values.
    // In that case I would be able use fillUpValuesByFullYear() instead of recalculate everything again
    const getDefaultMonthlyNetForPaidInCashBenefit = (i) => {
        return toNet(user.limitsMonthlyGross[i])
    }

    const getSumDefaultMonthlyGrossForPaidInCashBenefitExceptDecember = () => {
        return [...Array(11).keys()].reduce((acc, cur) => toGross(getDefaultMonthlyNetForPaidInCashBenefit(cur)) + acc, 0)
    }

    const getSumDefaultMonthlyNetForPaidInCashBenefitExceptDecember = () => {
        return [...Array(11).keys()].reduce((acc, cur) => getDefaultMonthlyNetForPaidInCashBenefit(cur) + acc, 0)
    }

    const getDefaultDecemberNetForPaidInCashBenefit = () => {
        const remainingValueGross = userLimitYearlyGross - getSumDefaultMonthlyGrossForPaidInCashBenefitExceptDecember()
        return toNet(remainingValueGross)
    }

    const getDefaultMonthlyNet = (i, row) => {
        let ret = 0
        if (isPaidInCashBenefit(fetchedBenefit.id) && initialAllocationsYearlyNet === 0) {
            if (i + 1 === 12) {
                ret = getDefaultDecemberNetForPaidInCashBenefit()
            } else {
                ret = getDefaultMonthlyNetForPaidInCashBenefit(i)
            }
        } else {
            ret = row
        }

        return ret
    }

    const getDefaultMonthlyGross = (i, row) => {
        return toGross(getDefaultMonthlyNet(i, row))
    }

    const getDefaultYearlyNet = () => {
        let ret = 0
        if (isPaidInCashBenefit(fetchedBenefit.id) && initialAllocationsYearlyNet === 0) {
            ret = getSumDefaultMonthlyNetForPaidInCashBenefitExceptDecember() + getDefaultDecemberNetForPaidInCashBenefit()
        } else {
            ret = initialAllocationsYearlyNet
        }

        return nbrToStr(ret)
    }

    const getDefaultYearlyGross = () => {
        let ret = 0
        if (isPaidInCashBenefit(fetchedBenefit.id) && initialAllocationsYearlyNet === 0) {
            ret = getSumDefaultMonthlyGrossForPaidInCashBenefitExceptDecember() + toGross(getDefaultDecemberNetForPaidInCashBenefit())
        } else {
            ret = initialAllocationsYearlyGross
        }

        return nbrToStr(ret)
    }

    const classes = useStyles()

    return (
        <BasePage>
            <DisablePageDialog title="Nem módosítható!" message="Már jóváhagytad az igénylésed." isOpen={showAlreadyFinalizedAlertDialog} fromUrl={`/users/${userId}/benefits`} />
            <DisablePageDialog title="Nem módosítható!" message={ERR_IS_PAID_IN_CASH} isOpen={showPaidInCashAlertDialog} fromUrl={`/users/${userId}/benefits`} />
            <br />
            <Breadcrumb elements={[
                { name: "Admin", href: "/admin" },
                { name: "Felhasználók", href: "/users" },
                { name: user.username ? user.username : "", href: `/users/${userId}` },
                { name: "Kafetéria igénylés", href: `/users/${user.id}/benefits` },
                { name: "Kezelés", href: "" }
            ]} />
            <UserIndicator user={user} />
            <form onSubmit={handleSubmit(onSubmit)}>
                {fetchedBenefit &&
                    <Grid container>
                        <Grid item xs={5}>
                            <br />
                            {showNotValidateChkBox &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={shouldValidate}
                                            onChange={(e) => {
                                                setShouldValidate(e.target.checked)
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Validálás"
                                />}
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                startIcon={<SaveIcon />}
                                disabled={
                                    fullYearErrorMessage !== "" || (isPaidInCashBenefit(fetchedBenefit.id) && allOtherBenefitAllocationsYearlyGross > 1)
                                }
                            >
                                Mentés
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={() => {
                                    const payload = { allocationSaveStatus: ALLOCATION_SAVE_STATUS.warning }
                                    history.push(`/users/${userId}/benefits`, payload)
                                }}
                            >
                                Mégse
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={() => {
                                    for (let i = 1; i <= 12; i++) {
                                        setValue(i + NET_VALUE_ID_POSTFIX, "")
                                        setValue(i + GROSS_VALUE_ID_POSTFIX, "")
                                        setValue("fullYearValueNet", "", { shouldValidate: true })
                                        setValue("fullYearValueGross", "", { shouldValidate: true })
                                    }
                                }}
                            >
                                Adatok törlése
                            </Button>
                            <br /> <br />
                            <Typography variant="h5">
                                {fetchedBenefit.name}
                            </Typography>
                            <Box align="center">
                                Szorzó: {fetchedBenefit.taxMultiplier}
                                <br />

                                {isOTPBenefit(fetchedBenefit.id) || isOTPBenefitHighTaxRated(fetchedBenefit.id) ? "" :
                                    "Igényelhető maximum: " + (fetchedBenefit.limitYearlyNet ? nbrToStr(fetchedBenefit.limitYearlyNet) + "/ év" : "Nincs")
                                }

                            </Box>

                            <br />

                            {fetchedAllocations && fetchedBenefit && (isOTPBenefit(fetchedBenefit.id) || isOTPBenefitHighTaxRated(fetchedBenefit.id)) &&
                                <>
                                    {`Add meg az ${fetchedBenefit.name} számlaszámát:`}
                                    <br />
                                    <TextField
                                        inputProps={{ style: { width: "10ch", textAlign: "center" } }}
                                        defaultValue={isOTPBenefit(fetchedBenefit.id) ? fetchedBenefit.accountNumberPrefix.split("-")[0] : fetchedAllocations.accountNumber && fetchedAllocations.accountNumber.split("-")[0]}
                                        disabled={true} />
                                    -
                                    <TextField
                                        inputProps={{ style: { width: "10ch", textAlign: "center" } }}
                                        defaultValue={isOTPBenefit(fetchedBenefit.id) ? fetchedBenefit.accountNumberPrefix.split("-")[1] : fetchedAllocations.accountNumber && fetchedAllocations.accountNumber.split("-")[1]}
                                        disabled={true} />
                                    -
                                    <TextField
                                        name="accountNumber"
                                        onKeyPress={(e) => {
                                            if (! "1234567890".includes(e.key)) {
                                                e.preventDefault()
                                            }
                                        }}
                                        helperText={errors.accountNumber && errors.accountNumber.message}
                                        error={errors["accountNumber"]}
                                        inputProps={{ style: { width: "10ch", textAlign: "center" } }}
                                        defaultValue={fetchedAllocations.accountNumber && fetchedAllocations.accountNumber.split("-")[2]}
                                        autoComplete="off"
                                        inputRef={register({
                                            required: { value: true, message: ERR_ACCOUNTNUMBER_REQUIRED },
                                            maxLength: { value: 8, message: ERR_ACCOUNTNUMBER_MAX8 },
                                            minLength: { value: 8, message: ERR_ACCOUNTNUMBER_MIN8 },
                                        }
                                        )}
                                        disabled={isOTPBenefitHighTaxRated(fetchedBenefit.id)}
                                    />
                                </>
                            }
                            <br />
                            <BenefitAllocationNotes benefitComment={fetchedBenefit.comment} noteDetails={activeNoteDetails} />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={6}>
                            {fetchedAllocations && <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="Benefit table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Hónap</TableCell>
                                            <TableCell align="left">Igényelt</TableCell>
                                            <TableCell align="left">Felhasznált</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            fetchedAllocations.currentBenefitAllocationsMonthlyNet.map((row, i) => {
                                                const checkIsThereValidationErrorForMonth = errors[i + 1 + NET_VALUE_ID_POSTFIX] && errors[i + 1 + NET_VALUE_ID_POSTFIX].type === "validate"
                                                return (
                                                    <TableRow key={i + 1} hover>
                                                        <TableCell >{getMonthName(i + 1)} </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                name={i + 1 + NET_VALUE_ID_POSTFIX}
                                                                autoComplete="off"
                                                                helperText={checkIsThereValidationErrorForMonth && errors[i + 1 + NET_VALUE_ID_POSTFIX].message}
                                                                error={checkIsThereValidationErrorForMonth}
                                                                defaultValue={nbrToStr(getDefaultMonthlyNet(i, row))}
                                                                InputProps={{
                                                                    readOnly: isPaidInCashBenefit(fetchedBenefit.id) && shouldValidate
                                                                }}
                                                                inputRef={register({
                                                                    validate: (v) => {
                                                                        if (!shouldValidate) {
                                                                            return
                                                                        }
                                                                        const val = strToNbr(v)
                                                                        if (i + 1 !== 12 && val > (fetchedBenefit.limitMonthlyNet || LIMITLESS)) {
                                                                            return ERR_MONTH_BENEFIT_LIMIT
                                                                        }
                                                                        if (i + 1 !== 12 && toNet(fetchedAllocations.userLimitsMonthlyGross[i] - fetchedAllocations.allOtherBenefitAllocationsMonthlyGross[i]) < val) {
                                                                            return ERR_MONTH_LIMIT
                                                                        }
                                                                        if (isOTPBenefit(fetchedBenefit.id) && i + 1 !== 12 && val + fetchedAllocations.allOtherGroupBenefitAllocationsMonthlyNet[i] > szepCardLimitMonthlyNet) {
                                                                            return ERR_MONTH_GROUP_LIMIT
                                                                        }
                                                                    }
                                                                })}
                                                                onChange={(e) => {
                                                                    e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (! "1234567890".includes(e.key)) {
                                                                        e.preventDefault()
                                                                    }
                                                                }}
                                                                onBlur={(event) => {
                                                                    // Need to recalculate on blur, not on render
                                                                    const currentValueNet = strToNbr(event.target.value)
                                                                    const currentValueGross = toGross(currentValueNet)
                                                                    setValue(i + 1 + GROSS_VALUE_ID_POSTFIX, nbrToStr(currentValueGross), { shouldValidate: true })
                                                                    setValue("fullYearValueNet", nbrToStr(sumCurrentValuesNet(1, 12)), { shouldValidate: true })
                                                                    setValue("fullYearValueGross", nbrToStr(sumCurrentValuesGross()), { shouldValidate: true })
                                                                    fullYearValidation()
                                                                }}
                                                                onFocus={(event) => {
                                                                    const yearlyGroupBenefitRemainingNet = isOTPBenefit(fetchedBenefit.id) ? (szepCardLimitYearlyNet - getAllOtherGroupBenefitAllocationsYearlyNet() - sumCurrentValuesNet(1, 12)) + strToNbr(event.target.value) : LIMITLESS
                                                                    const yearlyBenefitRemainingNet = ((fetchedBenefit.limitYearlyNet || LIMITLESS) - sumCurrentValuesNet(1, 12)) + strToNbr(event.target.value)
                                                                    const yearlyBenefitFirstSixMonthsRemainingNet = isOTPBenefit(fetchedBenefit.id) ? (fetchedBenefit.limitFirstSixMonthsNet - sumCurrentValuesNet(1, 6)) + strToNbr(event.target.value) : LIMITLESS
                                                                    const yearlyBenefitConditionalYearlyRemainingNet = isOTPBenefit(fetchedBenefit.id) ? getYearlyBenefitRemainingOverFirstSixMonthsNet() - sumCurrentValuesNet(7, 12) + strToNbr(event.target.value) : LIMITLESS
                                                                    const additionalBenefitYearlyRemainingNet = isOTPBenefit(fetchedBenefit.id) ?
                                                                        (i + 1 <= 6 ? yearlyBenefitFirstSixMonthsRemainingNet : yearlyBenefitConditionalYearlyRemainingNet)
                                                                        : LIMITLESS
                                                                    let remainingNetValue = 0
                                                                    let userLimitGross = 0
                                                                    let alreadyAllocatedValueGross = 0
                                                                    const alreadyAllocatedValueYearlyGross = allOtherBenefitAllocationsYearlyGross + (sumCurrentValuesGross() - toGross(strToNbr(event.target.value)))
                                                                    const yearlyUserLimitRemainingNet = calcYearlyUserLimitRemainingNet(alreadyAllocatedValueYearlyGross, userLimitYearlyGross, fetchedBenefit.taxMultiplier)
                                                                    if (i + 1 === 12) {
                                                                        remainingNetValue = Math.min(
                                                                            yearlyUserLimitRemainingNet,
                                                                            yearlyGroupBenefitRemainingNet,
                                                                            yearlyBenefitRemainingNet,
                                                                            additionalBenefitYearlyRemainingNet,
                                                                        )
                                                                        userLimitGross = userLimitYearlyGross
                                                                        alreadyAllocatedValueGross = alreadyAllocatedValueYearlyGross
                                                                    } else {
                                                                        const monthlyGroupLimitRemaining = isOTPBenefit(fetchedBenefit.id) ? szepCardLimitMonthlyNet - fetchedAllocations.allOtherGroupBenefitAllocationsMonthlyNet[i] : LIMITLESS
                                                                        remainingNetValue = Math.min(
                                                                            yearlyBenefitRemainingNet,
                                                                            yearlyGroupBenefitRemainingNet,
                                                                            fetchedBenefit.limitMonthlyNet,
                                                                            monthlyGroupLimitRemaining,
                                                                            toNet(fetchedAllocations.userLimitsMonthlyGross[i] - (fetchedAllocations.allOtherBenefitAllocationsMonthlyGross[i] || 0)),
                                                                            additionalBenefitYearlyRemainingNet,
                                                                            yearlyUserLimitRemainingNet,
                                                                        )
                                                                        userLimitGross = fetchedAllocations.userLimitsMonthlyGross[i]
                                                                        alreadyAllocatedValueGross = fetchedAllocations.allOtherBenefitAllocationsMonthlyGross[i] || 0
                                                                    }

                                                                    const remainingNetValueNegativeCorrection = remainingNetValue < 0 ? 0 : remainingNetValue
                                                                    setActiveNoteDetails(
                                                                        {
                                                                            benefitId: fetchedBenefit.id,
                                                                            monthName: getMonthName(i + 1),
                                                                            userLimitGross: nbrToStr(userLimitGross),
                                                                            alreadyAllocatedValueGross: nbrToStr(alreadyAllocatedValueGross),
                                                                            remainingNetValue: nbrToStr(remainingNetValueNegativeCorrection),
                                                                            remainingValueGross: nbrToStr(toGross(remainingNetValueNegativeCorrection)),
                                                                            allOtherGroupBenefitAllocationMonthlyGross: nbrToStr(fetchedAllocations.allOtherGroupBenefitAllocationsMonthlyGross[i]),
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                name={i + 1 + GROSS_VALUE_ID_POSTFIX}
                                                                autoComplete="off"
                                                                defaultValue={nbrToStr(getDefaultMonthlyGross(i, row))}
                                                                InputProps={{
                                                                    readOnly: true
                                                                }}
                                                                inputRef={register}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {<Link href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                fillUpValuesFromCurrentMonth(i + 1, getValues(i + 1 + NET_VALUE_ID_POSTFIX))
                                                            }}>
                                                                Beállítom további hónapokra
                                                            </Link>}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        <TableRow hover>
                                            <TableCell> Teljes év </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    name="fullYearValueNet"
                                                    autoComplete="off"
                                                    defaultValue={getDefaultYearlyNet()}
                                                    helperText={fullYearErrorMessage}
                                                    error={fullYearErrorMessage !== ""}
                                                    disabled={isPaidInCashBenefit(fetchedBenefit.id) && shouldValidate}
                                                    inputRef={register}
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (! "1234567890".includes(e.key)) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                >
                                                </TextField >
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    name="fullYearValueGross"
                                                    autoComplete="off"
                                                    defaultValue={getDefaultYearlyGross()}
                                                    disabled={isPaidInCashBenefit(fetchedBenefit.id) && shouldValidate}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    inputRef={register}
                                                >
                                                </TextField >
                                            </TableCell>
                                            <TableCell align="right">
                                                {<Link href="#" onClick={(e) => { e.preventDefault(); fillUpValuesByFullYear(getValues("fullYearValueNet")); }}>
                                                    Arányosan feltöltöm a hónapokat
                                                </Link>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            }
                        </Grid>
                    </Grid>
                }
            </form >
        </BasePage >
    )

}

export default BenefitAllocationPage