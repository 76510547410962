import React, { useState } from 'react'
import { Button, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { login } from '../api/api.js'
import { Typography, TextField } from '@material-ui/core';

function LoginPage(props) {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const doLogin = async () => {
        try {
            const currentUser = await login(username, password)
            history.push(`/users/${currentUser.id}/benefits`);
        } catch (err) {
            console.log("Login failed, err: ", err)
            setErrorMessage("Hibás felhasználó név vagy jelszó!")
        }
    }


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Typography variant="h4">
                Cafeteria bejelentkezés
            </Typography>
            <br />
            <TextField
                autoFocus
                name="username"
                label="Felhasználó"
                onChange={e => setUsername(e.target.value)}
                value={username}
            />
            <br />
            <TextField
                name="password"
                label="Jelszó"
                onChange={e => setPassword(e.target.value)}
                onKeyPress={e => {
                    if (e.key === "Enter") {
                        doLogin()
                    }
                }}
                value={password}
                type="password"
            />
            <br />
            <Button
                color="primary"
                variant="contained"
                onClick={doLogin}>
                Belépés
            </Button>
            <br />
            <Box color="error.main">
                {errorMessage}
            </Box>
        </Box >
    )
}

export default LoginPage