import React from 'react';
import { BasePage } from "./BasePage.js"
import { Container, ListItem, ListItemText, Box, Typography, Paper } from '@material-ui/core';
import { Breadcrumb } from "../components/Breadcrumb.js"

function ListItemLink(props) {
    return <ListItem style={{ textAlign: "center" }} button component="a" {...props} />;
}

function AdminPage(props) {
    return (
        <BasePage>
            <Typography variant="h4">
                Karbantartás
            </Typography>
            <br />
            <Breadcrumb elements={[{ name: "Admin", href: "/admin" }]} />
            <Container maxWidth="sm">
                <Paper>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <ListItemLink href="/users">
                            <ListItemText primary="Felhasználók" />
                        </ListItemLink>
                        <ListItemLink href="/settings">
                            <ListItemText primary="Beállítások" />
                        </ListItemLink>
                        <ListItemLink href="/benefits">
                            <ListItemText primary="Tikettek" />
                        </ListItemLink>
                    </Box>
                </Paper>
            </Container>

        </BasePage >
    )

}

export default AdminPage