import React from 'react'
import Header from "../components/Header"

function BasePage(props) {
    return (
        <div>
            <Header />
            <div id="content-container">
                {props.children}
            </div>
        </div>
    )
}

export {
    BasePage
}