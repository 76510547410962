import React, { useState } from "react"
import { useHistory } from "react-router"
import { Box, Button, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { changePassword } from "../api/user-api.js"


function ChangePasswordPage(props) {
    const history = useHistory()
    const { userId } = props.match.params
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordAgain, setNewPasswordAgain] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Typography variant="h4">
                Jelszó módosítás
        </Typography>
            <br />
            <form noValidate>
                {/* only cos of password managers */}
                <input autoComplete="username" type="text" hidden={true} />
                <TextField
                    label="Régi jelszó"
                    autoComplete="old-password"
                    onChange={e => setOldPassword(e.target.value)}
                    value={oldPassword}
                    type="password"
                />
                <br />
                <TextField
                    label="Új jelszó"
                    autoComplete="new-password"
                    onChange={e => setNewPassword(e.target.value)}
                    value={newPassword}
                    type="password"
                />
                <br />
                <TextField
                    label="Új jelszó még egyszer"
                    autoComplete="new-password-again"
                    onChange={e => setNewPasswordAgain(e.target.value)}
                    value={newPasswordAgain}
                    type="password"
                />
                <br /><br /><br />
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={async () => {
                            if (newPassword !== newPasswordAgain) {
                                setErrorMessage("A két jelszó nem egyezik!")
                            } else {
                                const { status } = await changePassword(userId, oldPassword, newPassword)
                                if (status === 422) {
                                    setErrorMessage("A régi jelszó nem egyezik!")
                                } else {
                                    console.info("Password successfully changed")
                                    history.push(`/users/${localStorage.getItem("current-user-id")}/benefits`)
                                }
                            }
                        }}
                    >
                        Mentés
                </Button>
                        &nbsp;&nbsp;&nbsp;
                <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        href={`/users/${localStorage.getItem("current-user-id")}/benefits`}
                    >
                        Mégse
                </Button>
                </Box>
                <br />
                <Box color="error.main">
                    {errorMessage}
                </Box>
            </form>
        </Box>
    )
}

export default ChangePasswordPage