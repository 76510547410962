import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import BenefitSummaryPage from "./pages/BenefitSummaryPage.js"
import BenefitAllocationPage from "./pages/BenefitAllocationPage.js"
import UserDetailPage from "./pages/UserDetailPage.js"
import BenefitDetailsPage from "./pages/BenefitDetailsPage.js"
import UserAllocationLimitsPage from "./pages/UserAllocationLimitsPage.js"
import BenefitAdminPage from "./pages/BenefitAdminPage.js"
import UsersPage from "./pages/UsersPage.js"
import AdminPage from "./pages/AdminPage.js"
import LoginPage from "./pages/LoginPage.js"
import SettingsPage from "./pages/SettingsPage.js"
import QueriesPage from "./pages/QueriesPage.js"
import ChangePasswordPage from './pages/ChangePasswordPage'
import SettingsBenefitGroupPage from './pages/SettingsBenefitGroupPage'
import VersionPage from './pages/VersionPage'
import DocumentsPage from './pages/DocumentsPage'

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/version" component={VersionPage} exact />
          <Route path="/login" component={LoginPage} exact />
          <Route path="/users/:userId/benefits" component={BenefitSummaryPage} exact />
          <Route path="/users/:userId" component={UserDetailPage} exact />
          <Route path="/create-user" component={UserDetailPage} exact />
          <Route path="/users/:userId/benefits/:benefitId" component={BenefitAllocationPage} exact />
          <Route path="/users/:userId/limits" component={UserAllocationLimitsPage} exact />
          <Route path="/create-benefit" component={BenefitDetailsPage} exact />
          <Route path="/benefits/:id" component={BenefitDetailsPage} exact />
          <Route path="/benefits" component={BenefitAdminPage} exact />
          <Route path="/users" component={UsersPage} exact />
          <Route path="/admin" component={AdminPage} exact />
          <Route path="/queries" component={QueriesPage} exact />
          <Route path="/settings" component={SettingsPage} exact />
          <Route path="/settings-benefit-group" component={SettingsBenefitGroupPage} exact />
          <Route path="/users/:userId/change-password" component={ChangePasswordPage} exact />
          <Route path="/users/:userId/documents" component={DocumentsPage} exact />
          <Route path="/" component={LoginPage} />
          {/* <Route path="*" component={Notfound}/> */}
        </Switch>
      </div>

    </Router>
  );
}

export default App;
