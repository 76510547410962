import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router"
import { BasePage } from "./BasePage.js"
import { Container, Box, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { useForm } from "react-hook-form";
import { getSettings, modifySettings } from "../api/settings-api.js"

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '200px',
        }
    },
}));


function SettingsPage(props) {
    const history = useHistory();
    const classes = useStyles();
    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const onSubmit = async (formData) => {
        await modifySettings(formData)
        history.push("/admin")
    }

    const [fetchedSettings, setFetchedSettings] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const result = await getSettings()
            if (result) {
                setFetchedSettings(result)
            }
        }
        fetchData();
    }, []);

    return (
        <BasePage>
            <Typography variant="h4">
                Beállítások
            </Typography>
            <Breadcrumb elements={[{ name: "Admin", href: "/admin" }, { name: "Beállítások", href: "/settings" }]} />
            <Container maxWidth="xs">
                {fetchedSettings && <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={classes.root}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >

                        <TextField fullWidth type="number" name="targetYear" label="Igénylés éve" defaultValue={fetchedSettings.targetYear} inputRef={register()} />
                        <br />
                        <TextField fullWidth name="companyName" label="Cég név" defaultValue={fetchedSettings.companyName} inputRef={register()} />
                        <br />
                        <TextField fullWidth name="companyCity" label="Cég város" defaultValue={fetchedSettings.companyCity} inputRef={register()} />
                        <br /><br />
                        <Box>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<SaveIcon />}
                            >
                                Mentés
                              </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                href="/admin"
                            >
                                Mégse
                                 </Button>
                        </Box>
                    </Box>
                </form>
                }
            </Container>
        </BasePage >
    )

}

export default SettingsPage