import React, { useEffect, useState } from 'react';
import { BasePage } from "./BasePage.js"
import { Button, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Link, Box, Typography, TextField, Paper, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getUsers, deleteUser } from '../api/user-api.js';
import { getFinalizeStats } from '../api/stats-api.js';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { ConfirmDialog } from '../util/helper.js';
import { ShowFinalizeStat } from '../components/ShowFinalizeStat'

const useStyles = makeStyles({
    table: {
        minWidth: 300,
        '& th': {
            border: "1px blue solid",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
        },
    },
    container: {
        maxHeight: "calc(100vh - 320px)",
    },
});


function UsersPage(props) {
    const classes = useStyles();

    const [fetchedUsers, setFetchedUsers] = useState([])
    const [fetchedStats, setFetchedStats] = useState({})
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [filterFinalized, setFilterFinalized] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await getUsers()
            if (result) {
                setFetchedUsers(result.sort((a, b) => Intl.Collator('hu').compare(a.lastName, b.lastName)))
            }
        }
        fetchData()
    }, [selectedUser])

    useEffect(() => {
        const fetchData = async () => {
            const result = await getFinalizeStats()
            if (result) {
                setFetchedStats(result)
            }
        }
        fetchData()
    }, [])


    return (
        <BasePage>
            <ConfirmDialog title="Törlés"
                message={<> Biztos törlöd a következő felhasználót: <b> {selectedUser && selectedUser.username} </b> ? </>}
                isOpen={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                submit={() => {
                    deleteUser(selectedUser.id)
                    setSelectedUser(null)
                }}
            />
            <Typography variant="h4">
                Felhasználók kezelése
            </Typography>
            <Breadcrumb elements={[{ name: "Admin", href: "/admin" }, { name: "Felhasználók", href: "/users" }]} />
            <Button
                variant="contained"
                color="primary"
                href="/create-user"
            >
                Új felhasználó
            </Button>
            <Box m={2} align="left">
                <SearchIcon></SearchIcon>
                <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <br />
                <FormControlLabel
                    control={<Checkbox
                        name="isFinalized"
                        checked={filterFinalized}
                        onChange={(e) => setFilterFinalized(e.target.checked)}
                    />}
                    label="Csak a nem véglegesítettek"
                />
            </Box>
            <br />
            <ShowFinalizeStat countAll={fetchedStats.countAll} countTouched={fetchedStats.countTouched} countFinalized={fetchedStats.countFinalized} />
            <Divider />
            <br />
            <TableContainer className={classes.container} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="Tickets table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"> Felhasználónév</TableCell>
                            <TableCell align="left">Vezetéknév</TableCell>
                            <TableCell align="left">Keresztnév</TableCell>
                            <TableCell align="left">Adóazonosító</TableCell>
                            <TableCell align="left">Osztály</TableCell>
                            <TableCell align="left">Véglegesítve</TableCell>
                            <TableCell align="left">Rendszergazda</TableCell>
                            <TableCell align="left">Teszt felhasználó</TableCell>
                            <TableCell>Kafetéria igénylés</TableCell>
                            <TableCell>Limitek</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fetchedUsers.filter((u) => {
                            return (
                                (
                                    (u.username && u.username.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                                    (u.taxId && u.taxId.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                                    (u.lastName && u.lastName.toLowerCase().includes(searchText.toLowerCase())) ||
                                    (u.firstName && u.firstName.toLowerCase().includes(searchText.toLowerCase()))
                                ) &&
                                (
                                    !filterFinalized || !u.isAllocationFinalized
                                )
                            )
                        }).map((row) => (
                            <TableRow key={row.id} hover>
                                <TableCell><Button onClick={() => { setSelectedUser(row); setShowDeleteDialog(true); }}><DeleteForeverIcon color="primary" fontSize="small" /> </Button></TableCell>
                                <TableCell align="left">
                                    <Link href={`/users/${row.id}`}>
                                        {row.username}
                                    </Link>
                                </TableCell>
                                <TableCell align="left">{row.lastName}</TableCell>
                                <TableCell align="left">{row.firstName}</TableCell>
                                <TableCell align="left">{row.taxId}</TableCell>
                                <TableCell align="left">{row.department}</TableCell>
                                <TableCell align="left">{row.isAllocationFinalized ? <CheckIcon fontSize="small" /> : <BlockIcon fontSize="small" />}</TableCell>
                                <TableCell align="left">{row.isAdmin ? <CheckIcon fontSize="small" /> : <BlockIcon fontSize="small" />}</TableCell>
                                <TableCell align="left">{row.isTestUser ? <CheckIcon fontSize="small" /> : <BlockIcon fontSize="small" />}</TableCell>
                                <TableCell align="left">
                                    {<Link href={`/users/${row.id}/benefits`}>
                                        <EditOutlinedIcon />
                                    </Link>}
                                </TableCell>
                                <TableCell align="left">
                                    {<Link href={`/users/${row.id}/limits`}>
                                        <EditOutlinedIcon />
                                    </Link>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </BasePage >
    )

}

export default UsersPage