import { preProcess, makeRequest } from './api.js'

const getBenefits = async () => {
    try {
        const response = await makeRequest(`benefits`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get benefits!', e);
    }
}

const getBenefit = async (id) => {
    try {
        const response = await makeRequest(`benefits/${id}`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get benefit!', e);
    }
}


const addBenefit = async (benefit) => {
    try {
        const response = await makeRequest(`benefits`, "POST", benefit)
        const preProc = preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to add benefit!', e);
    }
}

const modifyBenefit = async (benefit) => {
    try {
        const response = await makeRequest(`benefits/${benefit.id}`, "PUT", benefit, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to modify benefit!', e);
    }
}

const setAllocationsForBenefit = async (userId, benefitId, allocations) => {
    try {
        const response = await makeRequest(`allocations/users/${userId}/benefits/${benefitId}`, "PUT", allocations, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to modify allocations!', e);
        return { status: "error" }
    }
}

const getAllocations = async (userId) => {
    try {
        const response = await makeRequest(`allocations/users/${userId}`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get allocations for user!', e);
    }
}

const getAllocationsForBenefit = async (userId, benefitId) => {
    try {
        const response = await makeRequest(`allocations/users/${userId}/benefits/${benefitId}`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get allocations for benefit!', e);
    }
}

const downloadEmployeeSummary = async (userId) => {
    try {
        const response = await makeRequest(`allocations/users/${userId}/download-employee-summary`)
        const preProc = await preProcess(response)
        const blob = await preProc.blob()
        const fileUrl = URL.createObjectURL(blob)
        let fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.download = "igenyles.pdf"
        fileLink.click()
        URL.revokeObjectURL(fileUrl)
    }
    catch (e) {
        console.log('Failed to download employee summary!', e);
    }
}


const downloadCompanySummary = async () => {
    try {
        const response = await makeRequest(`allocations/download-company-summary`)
        const preProc = await preProcess(response)
        const blob = await preProc.blob()
        const fileUrl = URL.createObjectURL(blob)
        let fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.download = "cafeteria.xls"
        fileLink.click()
        URL.revokeObjectURL(fileUrl)
    }
    catch (e) {
        console.log('Failed to download excel!', e);
    }
}

const finalizeAllocations = async (userId) => {
    try {
        const response = await makeRequest(`users/${userId}/finalize`, "PUT", null, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to finalize benefit allocations!', e);
    }
}

const getGroupLimits = async () => {
    try {
        const response = await makeRequest(`benefits/group/limit`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get group limits!', e);
    }
}

const modifyGroupLimits = async (settings) => {
    try {
        const response = await makeRequest(`benefits/group/limit`, "PUT", settings, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to change group limits!', e);
    }
}

const deleteBenefit = async (benefitId) => {
    try {
        const response = await makeRequest(`benefits/${benefitId}`, "DELETE", null, null)
        await preProcess(response)
    }
    catch (e) {
        console.log('Failed to delete benefit!', e);
    }
}

export {
    getBenefits,
    getBenefit,
    addBenefit,
    modifyBenefit,
    getAllocations,
    getAllocationsForBenefit,
    setAllocationsForBenefit,
    downloadCompanySummary,
    downloadEmployeeSummary,
    finalizeAllocations,
    getGroupLimits,
    modifyGroupLimits,
    deleteBenefit,
}