import React from 'react';
import { Box } from '@material-ui/core';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';

export function BenefitAllocationNotes(props) {
    const {
        benefitId,
        monthName,
        userLimitGross,
        alreadyAllocatedValueGross,
        remainingValueGross,
        remainingNetValue,
        allOtherGroupBenefitAllocationMonthlyGross,
    } = props.noteDetails

    const initContentText = "Kattints bele egy havi mezőbe, és a program kiszámolja milyen összegben választhatsz."
    const otherGroupText = [1, 2, 3].includes(benefitId) ?
        ` Más SZÉP kártya alszámlákban erre a hónapra bruttó ${allOtherGroupBenefitAllocationMonthlyGross} Ft-ot használtál fel.`
        : ""
    return (
        <Box align="left" pl={1}>
            <ContactSupportOutlinedIcon fontSize="large" />
            {!monthName && initContentText}

            {monthName &&
                (monthName === "December" ? `Éves` : monthName + " hónapra a ") +
                ` kereted bruttó ${userLimitGross} Ft, amiből bruttó ${alreadyAllocatedValueGross} Ft-ot használtál fel.` +
                otherGroupText +
                " Ebből az elemből ebben a hónapban maximum nettó "
            }
            {monthName && <b> {remainingNetValue} </b>}
            {monthName && ` Ft-ért igényelhetsz (ami bruttó ${remainingValueGross} Ft-ot használ fel a burttó keretedből)`}
            <br />
            <br />
            <WbIncandescentOutlinedIcon fontSize="large" />
            {props.benefitComment}
        </Box >
    )
}