import { preProcess, makeRequest } from './api.js'

export const getFinalizeStats = async () => {
    try {
        const response = await makeRequest(`statistics/allocation-count`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get getFinalizeStats!', e);
    }
}