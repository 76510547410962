import React from "react"
import { Link } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { logout } from "../api/api.js"
import { isAdmin } from '../util/helper.js';


const useStyles = makeStyles((theme) => {
    return (
        {
            header: {
                height: "30px",
                display: "flex",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                borderBottom: "2px solid",
                justifyContent: "space-between",
                padding: "10px 10px 0px 10px",
            }
        }
    )
})


function Header(props) {
    const classes = useStyles();
    const theme = useTheme();

    const renderLoggedInPart = () => {
        return (
            <>
                Üdvözöllek {localStorage.getItem("current-user-name")}
                {isAdmin() &&
                    (<>
                        &nbsp; / <Link variant="body2" style={{ color: theme.palette.common.white }} href="/admin"> Adminisztráció </Link>
                        /
                        <Link variant="body2" style={{ color: theme.palette.common.white }} href="/queries"> Lekérdezések </Link>
                    </>)
                }
                &nbsp; /
                <Link variant="body2" style={{ color: theme.palette.common.white }} href={`/users/${localStorage.getItem("current-user-id")}/change-password`}> Jelszó módosítás </Link>
                / &nbsp;
                <Link variant="body2" style={{ color: theme.palette.common.white }} href="/login" onClick={() => { logout() }}>
                    Kijelentkezés
                </Link>
            </>
        )
    }

    return (
        <div className={classes.header} >
            <div>
                <Link color="inherit" href={`/users/${localStorage.getItem("current-user-id")}/benefits`}> Cafeteria {new Date().getFullYear()} </Link>
            </div>
            <div>
                {localStorage.getItem("jwt-token") ? renderLoggedInPart() :
                    <Link variant="body2" style={{ color: theme.palette.common.white }} href="/login"> Bejelentkezés </Link>}
            </div>
        </div >
    )
}

export default Header