export const FRONTEND_VERSION = "v1.1.6"

export const ERROR_MESSAGES = {
    FIELD_REQUIRED: "Kötelező",
    FIELD_ALREADY_EXISTS: "Már létezik",
}

export const zeroArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

export const ALLOCATION_SAVE_STATUS = {
    success: "success",
    warning: "warning",
    error: "error",
}