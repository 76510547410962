import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router"
import { BasePage } from "./BasePage.js"
import { Container, Box, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { Breadcrumb } from "../components/Breadcrumb.js"
import { useForm } from "react-hook-form";
import { getGroupLimits, modifyGroupLimits } from "../api/benefit-api.js"

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '200px',
        }
    },
}));


function SettingsPage(props) {
    const history = useHistory();
    const classes = useStyles();
    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const onSubmit = async (formData) => {
        await modifyGroupLimits(formData)
        history.push("/benefits")
    }

    const [fetchedBenefitGroupLimits, setFetchedBenefitGroupLimits] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const result = await getGroupLimits()
            if (result) {
                setFetchedBenefitGroupLimits(result)
            }
        }
        fetchData();
    }, []);

    return (
        <BasePage>
            <Typography variant="h4">
                SZÉP Kártya keretek
            </Typography>
            <Breadcrumb elements={[{ name: "Admin", href: "/admin" }, { name: "Tikketek", href: "/benefits" }, { name: "Limitek", href: "/settings-benefit-group" }]} />
            <Container maxWidth="xs">
                {fetchedBenefitGroupLimits && <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={classes.root}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <TextField fullWidth type="number" name="benefitGroupMonthlyLimit" label="Havi összes" defaultValue={fetchedBenefitGroupLimits.benefitGroupMonthlyLimit} inputRef={register()} />
                        <br />
                        <TextField fullWidth type="number" name="benefitGroupYearlyLimit" label="Évi összes" defaultValue={fetchedBenefitGroupLimits.benefitGroupYearlyLimit} inputRef={register()} />
                        <br /><br />
                        <Box>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<SaveIcon />}
                            >
                                Mentés
                              </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                href="/benefits"
                            >
                                Mégse
                                 </Button>
                        </Box>
                    </Box>
                </form>
                }
            </Container>
        </BasePage >
    )

}

export default SettingsPage