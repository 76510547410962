import React, { useEffect } from 'react';
import { useHistory } from "react-router"
import { BasePage } from "./BasePage.js"
import { useForm } from "react-hook-form";
import { Table, Button, TextField, Container } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { modifyUser } from '../api/user-api.js'
import SaveIcon from '@material-ui/icons/Save';
import { UserIndicator } from '../components/UserIndicator.js'
import { strToNbr, getMonthName, useFetchUser, nbrToStr } from '../util/helper.js';
import { Breadcrumb } from "../components/Breadcrumb.js"

const useStyles = makeStyles({
    table: {
        minWidth: 300,
        '& th': {
            border: "1px blue solid",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
        }
    }
});

function UserAllocationLimitsPage(props) {
    const { userId } = props.match.params
    const { register, handleSubmit, setValue, getValues } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const history = useHistory();
    const classes = useStyles();
    const user = useFetchUser(userId)

    const sumNetValues = () => {
        let sumGrossValues = 0
        for (var i = 1; i <= 12; i++) {
            if (getValues(i.toString())) {
                sumGrossValues += strToNbr(getValues(i.toString()))
            }
        }
        return sumGrossValues
    }


    useEffect(() => {
        user.limitsMonthlyGross && user.limitsMonthlyGross.forEach((e, i) => {
            setValue((i + 1).toString(), e && nbrToStr(e))
        })
        const sumDefaultGrossValues = user.limitsMonthlyGross && user.limitsMonthlyGross.reduce((acc, cur) => acc + cur, 0);
        setValue("fullYearNetValue", sumDefaultGrossValues && nbrToStr(sumDefaultGrossValues))
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmit = async (allocations) => {
        delete allocations.fullYearNetValue
        const allocationTransformed = Object.values(allocations).reduce((acc, cur) => {
            const currentValue = cur === "" ? null : strToNbr(cur)
            acc.push(currentValue)
            return acc
        }, [])

        const modifiedUser = { ...user }
        modifiedUser.limitsMonthlyGross = allocationTransformed
        await modifyUser(modifiedUser)
        history.push("/users")
    }

    const fillUpValuesByFullYear = (amountForYear) => {
        const amountPerMonth = nbrToStr(Math.floor(strToNbr(amountForYear) / 12))
        for (let i = 1; i <= 12; i++) {
            setValue(i.toString(), amountPerMonth)
        }
        setValue("fullYearNetValue", nbrToStr(sumNetValues()))
    }

    const fillUpValuesFromCurrentMonth = (currentMonth, currentAmount) => {
        for (let i = currentMonth; i <= 12; i++) {
            setValue(i.toString(), nbrToStr(currentAmount))
        }
        setValue("fullYearNetValue", nbrToStr(sumNetValues()))
    }

    return (
        <BasePage>
            <br />
            <Breadcrumb elements={[
                { name: "Admin", href: "/admin" },
                { name: "Felhasználók", href: "/users" },
                { name: user.username ? user.username : "", href: `/users/${userId}` },
                { name: "Limitek", href: "" }
            ]} />
            <UserIndicator user={user} />
            <br />
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="max alloc table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Hónap</TableCell>
                                    <TableCell align="left">Felhasználható</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    user.limitsMonthlyGross && user.limitsMonthlyGross.map((row, i) => {
                                        return (
                                            <TableRow key={i + 1} hover>
                                                <TableCell >{getMonthName(i + 1)} </TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        inputRef={register()}
                                                        name={(i + 1).toString()}
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (! "0123456789".includes(e.key)) {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        onBlur={() => { setValue("fullYearNetValue", nbrToStr(sumNetValues())) }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {<Link href="" onClick={(e) => {
                                                        e.preventDefault()
                                                        fillUpValuesFromCurrentMonth(i + 1, getValues((i + 1).toString()))
                                                    }}>
                                                        Beállítom további hónapokra
                                                        </Link>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                <TableRow hover>
                                    <TableCell> Teljes év </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            autoComplete="off"
                                            name="fullYearNetValue"
                                            inputRef={register}
                                            onChange={(e) => {
                                                e.target.value = e.target.value !== "" ? nbrToStr(strToNbr(e.target.value)) : ""
                                            }}
                                            onKeyPress={(e) => {
                                                if (! "1234567890".includes(e.key)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        >
                                        </TextField >
                                    </TableCell>
                                    <TableCell align="right">
                                        {<Link href="" onClick={(e) => {
                                            e.preventDefault();
                                            fillUpValuesByFullYear(getValues("fullYearNetValue"))
                                        }}>
                                            Arányosan feltöltöm a hónapokat
                                            </Link>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        Mentés
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        href="/users"
                    >
                        Mégse
                </Button>
                </form >
            </Container>
        </BasePage >
    )

}

export default UserAllocationLimitsPage