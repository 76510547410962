import React from 'react';

import { Breadcrumbs, Link } from '@material-ui/core';
import { isAdmin } from '../util/helper.js';

export function Breadcrumb(props) {
    if (!isAdmin()) {
        return null
    }
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {
                props.elements.map((e) => {
                    return (
                        <Link key={"bcrum-" + e.name} color="inherit" href={e.href}>
                            {e.name}
                        </Link>
                    )
                })
            }
        </Breadcrumbs>
    );
}