import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router"
import { BasePage } from "./BasePage.js"
import { Table, Link, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Tooltip, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { UserIndicator } from '../components/UserIndicator.js'
import { useFetchUser, getUserLimitYearlyGross, nbrToStr, SimpleDialog, ConfirmDialog, isOTPBenefitHighTaxRated, getCorrespondingStandardBenefitId, isAdmin, isHiddenBenefit } from '../util/helper.js';
import { ALLOCATION_SAVE_STATUS } from '../util/constants.js'
import { getAllocations, finalizeAllocations } from '../api/benefit-api.js';
import { modifyUser } from '../api/user-api.js';
import { Breadcrumb } from "../components/Breadcrumb.js"

const useStyles = makeStyles({
    table: {
        minWidth: 300,
        '& th': {
            border: "1px blue solid",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
        },
        '& td:first-child': {
            minWidth: "30ch"
        }
    }
});

function BenefitSummaryPage(props) {
    const history = useHistory()
    const { userId } = props.match.params
    const classes = useStyles()
    const [shouldFetchUser, setShouldFetchUser] = useState(false)
    const user = useFetchUser(userId, shouldFetchUser)
    const [showAlertDialog, setShowAlertDialog] = useState(false)
    const [fetchedAllocations, setFetchedAllocations] = useState([])
    const [showMonthlyColumns, setShowMonthlyColumns] = useState(false)
    const [showConfirmFinalizeDialog, setShowConfirmFinalizeDialog] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const allocSaveStatus = useRef("")

    useEffect(() => {
        const fetchData = async () => {
            const result = await getAllocations(userId)
            if (result) {
                // setFetchedAllocations(result.sort((a, b) => a.sequence - b.sequence))
                setFetchedAllocations(result.filter((b) => { return !isHiddenBenefit(b.id) }))
            }
        }
        fetchData();
        allocSaveStatus.current = props.location.state && props.location.state.allocationSaveStatus
        if (allocSaveStatus.current) {
            setShowSnackbar(true)
        }
        history.replace({ allocationSaveStatus: undefined })
    }, [userId]);

    const sumTotalValuesGrossForYear = fetchedAllocations && fetchedAllocations.reduce((accTotal, curTotal) => {
        const allocationsYearlyGross = curTotal.valuesMonthlyNet.reduce((acc, cur) => acc + Math.round(cur * curTotal.taxMultiplier), 0)
        return accTotal + allocationsYearlyGross
    }, 0)

    const userLimitGrossForYear = getUserLimitYearlyGross(user.limitsMonthlyGross)

    const hasStandardAllocation = (benefitId) => {
        const standardAllocation = fetchedAllocations.find((a) => a.id === getCorrespondingStandardBenefitId(benefitId))
        const standardAllocationMonthlyNet = standardAllocation && standardAllocation.valuesMonthlyNet.reduce((acc, cur) => acc + cur, 0)
        return standardAllocationMonthlyNet > 0
    }

    return (
        <BasePage>
            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={() => setShowSnackbar(false)} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert elevation={6} variant="filled" onClose={() => setShowSnackbar(false)} severity={allocSaveStatus.current}>
                    {allocSaveStatus.current === ALLOCATION_SAVE_STATUS.success ? "Sikeres mentés!"
                        : (allocSaveStatus.current === ALLOCATION_SAVE_STATUS.warning ? "Nem mentett!" : "Sikertelen mentés!")}
                </Alert>
            </Snackbar>
            <ConfirmDialog title="Véglegesítés"
                message="Biztos véglegesíted?"
                isOpen={showConfirmFinalizeDialog}
                handleClose={() => setShowConfirmFinalizeDialog(false)}
                submit={async () => {
                    await finalizeAllocations(userId)
                    setShouldFetchUser(!shouldFetchUser)
                }}
            />
            <br />
            <Breadcrumb elements={[
                { name: "Admin", href: "/admin" },
                { name: "Felhasználók", href: "/users" },
                { name: user.username ? user.username : "", href: `/users/${userId}` },
                { name: "Kafetéria igénylés", href: "" }
            ]} />
            <UserIndicator user={user} />
            <Box align="left">
                <Button
                    onClick={() => { setShowMonthlyColumns(!showMonthlyColumns) }}
                    color="primary"
                    size="small"
                >
                    Hónapok {showMonthlyColumns ? "elrejtése" : "megjelenítése"} a táblázatban
            </Button>
            </Box>
            <TableContainer component={Paper} style={{ height: "calc(100vh - 250px)" }}>
                <Table stickyHeader className={classes.table} aria-label="Benefit table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Juttatás</TableCell>
                            {showMonthlyColumns && (<>
                                <TableCell align="right">Jan</TableCell>
                                <TableCell align="right">Feb</TableCell>
                                <TableCell align="right">Márc</TableCell>
                                <TableCell align="right">Ápr</TableCell>
                                <TableCell align="right">Máj</TableCell>
                                <TableCell align="right">Jún</TableCell>
                                <TableCell align="right">Júl</TableCell>
                                <TableCell align="right">Aug</TableCell>
                                <TableCell align="right">Szep</TableCell>
                                <TableCell align="right">Okt</TableCell>
                                <TableCell align="right">Nov</TableCell>
                                <TableCell align="right">Dec</TableCell>
                            </>
                            )}
                            <TableCell align="right">Igényelhető maximum</TableCell>
                            <TableCell align="right">Igényelt</TableCell>
                            <TableCell align="right">Szorzó</TableCell>
                            <TableCell align="right">Felhasznált</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fetchedAllocations.map((row) => {
                            const allocationsYearlyNet = row.valuesMonthlyNet.reduce((acc, cur) => acc + cur, 0)
                            const allocationsYearlyGross = row.valuesMonthlyNet.reduce((acc, cur) => acc + Math.round(cur * row.taxMultiplier), 0)

                            const canOpenBenefit = (allocationsYearlyNet > 0 || !isOTPBenefitHighTaxRated(row.id) || hasStandardAllocation(row.id))
                            return (
                                <TableRow key={row.id} hover>
                                    <TableCell> {row.name} </TableCell>
                                    {showMonthlyColumns && row.valuesMonthlyNet.map((a, i) => {
                                        return (
                                            <TableCell key={row.id + "alloc" + i} align="right"> {nbrToStr(a)} </TableCell>
                                        )
                                    })}
                                    <TableCell align="right">{nbrToStr(row.limitYearlyNet)}</TableCell>
                                    <TableCell align="right">{nbrToStr(allocationsYearlyNet)}</TableCell>
                                    <TableCell align="right">{row.taxMultiplier}</TableCell>
                                    <TableCell align="right">{nbrToStr(Math.round(allocationsYearlyGross))}</TableCell>
                                    <TableCell align="right">
                                        <Tooltip
                                            title={canOpenBenefit ? "" : "Csak a standard kitöltése után."}>
                                            <Link
                                                href={canOpenBenefit ? `/users/${userId}/benefits/${row.id}` : "#"}>
                                                {canOpenBenefit ? <EditOutlinedIcon /> : <EditOutlinedIcon color="disabled" />
                                                }
                                            </Link>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleDialog title="Dokumentumok" message="Előbb véglegesítened kell az igénylésed." isOpen={showAlertDialog} handleClose={() => setShowAlertDialog(false)} />
            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <div style={{ display: "flex" }}>
                    {!user.isAllocationFinalized && (
                        <div> Ha elkészültél, kérlek&nbsp;
                            <Link
                                href="#"
                                onClick={() => setShowConfirmFinalizeDialog(true)}>
                                véglegesítsd az igénylésed
                            </Link>
                            &nbsp;és töltsd ki a
                            <Link
                                href=""
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (user.isAllocationFinalized) {
                                        history.push(`/users/${userId}/documents`)
                                    } else {
                                        setShowAlertDialog(true)
                                    }
                                }}>
                                &nbsp;dokumentumot
                            </Link>
                        </div>)
                    }
                    {user.isAllocationFinalized &&
                        (
                            <div> Véglegesítve! &nbsp;
                                <Link href="" onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`/users/${userId}/documents`)
                                }}>
                                    (Dokumentumok)
                                </Link>
                            </div>
                        )
                    }
                    {isAdmin() && user.isAllocationFinalized &&
                        <Link
                            href=""
                            onClick={async (e) => {
                                e.preventDefault()
                                let modifiedUser = { ...user }
                                modifiedUser.isAllocationFinalized = false
                                await modifyUser(modifiedUser)
                                setShouldFetchUser(!shouldFetchUser)
                            }} >
                            &nbsp; - Felold
                        </Link>
                    }
                </div>
                <div> {nbrToStr(userLimitGrossForYear)} bruttó éves keretedből {nbrToStr(Math.round(sumTotalValuesGrossForYear))} Ft-ot használtál fel,
                    maradt {nbrToStr(userLimitGrossForYear - Math.round(sumTotalValuesGrossForYear))} Ft </div>
            </div>
        </BasePage >
    )

}

export default BenefitSummaryPage