import { preProcess, makeRequest, logout } from './api.js'
import { zeroArray } from '../util/constants.js'

const addUser = async (user) => {
    try {
        user.limitsMonthlyGross = zeroArray
        const response = await makeRequest(`users`, "POST", user)
        const preProc = preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to add user!', e);
    }
}

const modifyUser = async (user) => {
    try {
        const response = await makeRequest(`users/${user.id}`, "PUT", user, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to modify user!', e);
    }
}

const getUsers = async () => {
    try {
        const response = await makeRequest(`users`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get users!', e);
    }
}

const getUser = async (id) => {
    try {
        const response = await makeRequest(`users/${id}`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        if (e === 403) {
            logout()
        }
        console.log('Failed to get user!', e);
    }
}

const getCurrentUser = async () => {
    try {
        const response = await makeRequest(`current-user`)
        const preProc = await preProcess(response)
        return preProc.json()
    }
    catch (e) {
        console.log('Failed to get current user!', e);
    }
}

const changePassword = async (userId, oldPassword, newPassword) => {
    try {
        const body = {
            oldPassword,
            newPassword,
        }
        const response = await makeRequest(`users/${userId}/change-password`, "PUT", body, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to change user password!', e);
    }
}

const resetPassword = async (userId, newPassword) => {
    try {
        const body = {
            newPassword,
        }
        const response = await makeRequest(`users/${userId}/reset-password`, "PUT", body, null)
        const preProc = await preProcess(response)
        let json = {}
        try {
            json = await preProc.json()
        } catch { }
        return { status: preProc.status, data: json }
    }
    catch (e) {
        console.log('Failed to reset user password!', e);
    }
}

const deleteUser = async (userId) => {
    try {
        const response = await makeRequest(`users/${userId}`, "DELETE", null, null)
        await preProcess(response)
    }
    catch (e) {
        console.log('Failed to delete user!', e);
    }
}


export {
    addUser,
    modifyUser,
    getUsers,
    getUser,
    getCurrentUser,
    changePassword,
    resetPassword,
    deleteUser,
}