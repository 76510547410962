import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getFullName } from '../util/helper.js'
import { isAdmin } from '../util/helper.js';

export function UserIndicator(props) {
    const { user } = props
    return (
        isAdmin() && user.id ?
            <Typography variant="h4">
                <Box bgcolor="warning.main">
                    {`${getFullName(user)} (${user.username}) `}
                </Box>
            </Typography> : null
    );
}